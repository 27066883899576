<template>

  <div>

    <listing-progress v-if="!listingFinished" :progress="totalProgress" :percentage="totalPercentage"></listing-progress>

    <div v-if="listingType" class="main">

      <div v-if="!listingFinished" class="container">
            <div class="tabs is-toggle is-fullwidth">
              <ul>
                <li :class="tabOneClassObject">
                  <a @click="changeTabs(1)"><span class="icon is-small"><font-awesome-icon icon="info-circle" /></span><span class="tab-text">Info</span></a>
                </li>
                <li :class="tabTwoClassObject">
                  <a @click="changeTabs(2)"><span class="icon is-small"><font-awesome-icon icon="clipboard-list" /></span><span class="tab-text">Extras</span></a>
                </li>
                <li :class="tabThreeClassObject">
                  <a @click="changeTabs(3)"><span class="icon is-small"><font-awesome-icon icon="camera" /></span><span class="tab-text">Pictures</span></a>
                </li>
                <li :class="tabFourClassObject">
                  <a @click="changeTabs(4)"><span class="icon is-small"><font-awesome-icon icon="star" /></span><span class="tab-text">Tags</span></a>
                </li>
                <li :class="tabFiveClassObject">
                  <a @click="changeTabs(5)"><span class="icon is-small"><font-awesome-icon icon="thumbs-up" /></span><span class="tab-text">Finish</span></a>
                </li>
              </ul>
            </div>  

            <div class="tab-content">


                <!--  TAB 1 ========================================================================================================  -->
                <div class="tab-content-inner" v-if="listTab == 1">
                  <div class="spacer s30"></div>
                  <h3 class="tab-content-heading"><font-awesome-icon icon="info-circle" /> Let's get some basic information about your {{listingType}}</h3>
                  <!-- <div class="tab-content-sub-heading">Everything in this section needs to be filled in.</div> -->
                  <div class="spacer s20"></div>
                  <div>
                    <label :class="{'empty': info.title == ''}">Give your {{listingType}} a title</label>
                    <input class="input" type="text" placeholder="eg: Deck tank for fishing boat..." v-model="info.title">
                  </div>
                  <div class="spacer s30"></div>
                  <div>
                    <label :class="{'empty': info.description == ''}">Provide some information about your {{listingType}}</label>
                    <textarea class="textarea" placeholder="Explain what this is and what features it has..." v-model="info.description"></textarea>
                  </div>
                  <!-- <div class="spacer s10"></div>
                  <div class="short-description-draft">
                    <div class="sd-heading">We'll use this as the short description:</div>
                    <span v-if="info.description.length > 0">{{capitalise(info.description.substring(0,200))}}</span>
                    <span v-else>Enter a description above</span>
                    <span v-if="info.description.length > 199">&nbsp;<font-awesome-icon icon="thumbs-up" /></span>
                  </div> -->
                  <div class="spacer s30"></div>

                  <div v-if="listingType == 'item'">

                    <label>Name your price</label>
                    <div class="dollar-wrapper">
                      <input class="input" type="tel" placeholder=" Leave blank to accept any offer" maxlength="8" v-on:keypress="isNumber($event)" v-model="info.price">
                    </div>

                    <div v-if="info.price != null && info.price != ''" class="choices-wrapper">
                      <ul class="choice-cloud">
                        <li v-for="(item, index) in priceOptions" :key="item.title"><span :class="{selected: item.selected}" @click="updatedSelection(priceOptions, index)">
                          <font-awesome-icon v-if="selectedPriceOptions.length == 0" icon="question-circle" />
                          <font-awesome-icon  v-else icon="check" />
                           &nbsp;{{item.title}}
                        </span></li>
                      </ul>
                    </div>

                  </div>

                  <div class="spacer s30"></div>

                  <div v-if="listingType == 'item'">
                    <label>Can you supply a Tax Invoice?</label>
                    <div class="choices-wrapper">
                      <ul class="choice-cloud">
                        <li v-for="(option, index) in invoiceOptions" :key="option.slug"><span :class="{selected: option.selected}" @click="updatedSingleSelection(invoiceOptions, index)">{{option.title}}</span></li>
                      </ul>
                    </div>
                  </div>
                  <div class="spacer s30"></div>

                  <div v-if="listingType == 'item'">
                    <label>Does the price include GST?</label>
                    <div class="choices-wrapper">
                      <ul class="choice-cloud">
                        <li v-for="(option, index) in gstOptions" :key="option.slug"><span :class="{selected: option.selected}" @click="updatedSingleSelection(gstOptions, index)">{{option.title}}</span></li>
                      </ul>
                    </div>
                  </div>
                  
                  <!-- <bulma-dropdown :options="options" @select="selected = $event" placeholder="Search"/>
                  {{selected}} -->
                  




                  

                 <!--  {{address.locality}} -->

                  <!-- <input ref="autocomplete" placeholder="Search" class="search-location" onfocus="value = ''" type="text" /> -->

                  <!-- <places-search></places-search> -->


                  <div class="spacer s80"></div>
                  <!-- <div class="buttons">
                    <span class="button is-primary is-medium" v-show="tabOneComplete" @click="listTab = 2">Next&nbsp;<font-awesome-icon icon="chevron-right" /></span>
                  </div> -->

                  <div class="clear"></div>


                </div>




                <!--  TAB 2 ========================================================================================================  -->
                <div class="tab-content-inner" v-if="listTab == 2">
                  <div class="spacer s30"></div>
                  <h3 class="tab-content-heading"><font-awesome-icon icon="info-circle" /> More information about your {{listingType}}</h3>
                  <!-- <div class="tab-content-sub-heading">Everything in this section needs to be filled in.</div> -->
                  <div class="spacer s20"></div>
                  <!-- {{address}} -->

                  <label :class="{'empty': address == '' && extras.suburbOverride == ''}">Where is your {{listingType}} located?</label>
                  <vue-google-autocomplete ref="address" permanently_closed="null" id="location-search" classname="form-control" :placeholder="locationPlaceholder" v-on:placechanged="getAddressData" types="(cities)" country="au"></vue-google-autocomplete>
                  <!-- <a class="sub-prompt simple-link" @click="showManualSuburbEntry = true">Suburb search not working?</a> -->
                  <div class="spacer s10"></div>
                  <div class="buttons">
                    <span class="button is-light is-small" @click="showManualSuburbEntry = true">Enter a different location</span>
                    <span class="button is-light is-small" @click="clearLocation()">Reset</span>
                  </div>
                  <div class="manual-suburb" v-if="showManualSuburbEntry">
                    <input class="input" type="text" placeholder="Type your suburb name..." v-model="extras.suburbOverride">
                  </div>
                  <div class="spacer s20"></div>

                  <div>
                    <label :class="{'empty': extras.contactName == ''}">Who is the best person to talk to?</label>
                    <input class="input" type="text" placeholder="Type a name..." v-model="extras.contactName">
                  </div>
                  <div class="buttons">
                    <span class="button is-light is-small" @click="extras.contactName = userData.username">Use my name</span>
                    <span class="button is-light is-small" @click="clearContactName()">Clear name</span>
                  </div>
                  <div class="spacer s20"></div>

                  <div>
                    <label :class="{'empty': preferredContactMethods.length == 0}">How would you like to be contacted?</label>
                    <div class="choices-wrapper">
                      <ul class="choice-cloud">
                        <li v-for="(method, index) in contactMethods" :key="method.title"><span :class="{selected: method.selected}" @click="updatedSelection(contactMethods, index)">{{method.title}}</span></li>
                      </ul>
                    </div>
                  </div>

                  <div v-show="preferredContactMethods.includes('phone') || preferredContactMethods.includes('sms')">
                    <label :class="{'empty': extras.contactPhone == ''}">Your mobile phone number is</label>
                    <input class="input compact" type="tel" placeholder=" Australian mobile number..." maxlength="10" v-on:keypress="isNumber($event)" v-model="extras.contactPhone">
                    <div class="clear"></div>
                  </div>

                  <div v-show="preferredContactMethods.includes('email')">
                    <label :class="{'empty': extras.contactEmail == ''}">Your email address is</label>
                    <input class="input compact" type="email" placeholder=" A valid email address..." maxlength="120" v-model="extras.contactEmail">
                    <div class="clear"></div>
                  </div>
                  <div class="spacer s30"></div>

                  <div v-if="listingType == 'item'">
                    <!-- <label :class="{'empty': preferredContactMethods.length == 0}">How would you like to be paid?</label> -->
                    <label>What are your preferred payment methods?</label>
                    <div class="choices-wrapper">
                      <ul class="choice-cloud">
                        <li v-for="(method, index) in paymentMethods" :key="method.title"><span :class="{selected: method.selected}" @click="updatedSelection(paymentMethods, index)">{{method.title}}</span></li>
                      </ul>
                    </div>
                  </div>
                  <div class="spacer s30"></div>



                  <!-- <div class="buttons">
                    <span class="button is-primary" v-show="tabTwoComplete" @click="listTab = 3">Next&nbsp;<font-awesome-icon icon="chevron-right" /></span>
                  </div> -->
                  <div class="clear"></div>
                  
                </div>




                <!--  TAB 3 ========================================================================================================  -->
                <div class="tab-content-inner" v-if="listTab == 3">
                  <div class="spacer s30"></div>
                  <h3 class="tab-content-heading"><font-awesome-icon icon="info-circle" /> Upload up to 8 photos {{listingType == 'item' ? '' : '(optional)'}}</h3>
                  <!-- <div class="tab-content-sub-heading">Everything in this section needs to be filled in.</div> -->
                  <div class="spacer s20"></div> 

                  <div>
                   
                    <!-- <vue-dropzone 
                      ref="imgDropZone" 
                      :destroyDropzone="false" 
                      id="xcustomdropzone" 
                      :options="dropzoneOptions"  
                      @vdropzone-thumbnail="thumbnailAdded" 
                      @vdropzone-file-added ="afterFiledAdded" 
                      @vdropzone-sending="beforeSending" 
                      @vdropzone-success="uploadedSuccessfully" 
                      @vdropzone-complete="afterComplete" 
                      @vdropzone-max-files-reached="maxReached" 
                      @vdropzone-removed-file="fileRemoved"> 

                    </vue-dropzone> -->

                    <vue-dropzone
                      ref="imgDropZone"
                      :include-styling="false"
                      :useCustomSlot="true"
                      id="dropzone" 
                      :options="dropzoneOptions" 
                      @vdropzone-upload-progress="uploadProgress" 
                      @vdropzone-thumbnail="thumbnailAdded" 
                      @vdropzone-file-added="afterFiledAdded" 
                      @vdropzone-sending="beforeSending" 
                      @vdropzone-success="uploadedSuccessfully" 
                      @vdropzone-complete="afterComplete" 
                      @vdropzone-max-files-reached="maxReached" 
                      @vdropzone-error="uploadError" 
                    >
                      <div class="dropzone-container">
                        <div class="file-selector">
                          <!-- Drop Or Add Files Here
                          <p class="separator"><span> or </span></p> -->
                          <button :disabled="imageCount > dropzoneOptions.maxFiles - 1" class="button is-primary">Upload a photo</button>
                        </div>
                      </div>
                    </vue-dropzone>


                   <!--  <image-uploader
                      :debug="1" 
                      :maxWidth="512" 
                      :quality="0.7" 
                      :autoRotate=true 
                      outputFormat="verbose" 
                      :preview=true 
                      :className="['fileinput', { 'fileinput--loaded' : hasImage }]" 
                      :capture="false" 
                      accept="image/*" 
                      doNotResize="['gif', 'svg']" 
                      @input="setImage" 
                    ></image-uploader> -->

                    <!-- <image-uploader
                      :preview="true"
                      :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                      capture="environment"
                      :debug="0"
                      :autoRotate="true"
                      outputFormat="verbose"
                      @input="setImage" 
                      @onUpload="startImageResize" 
                      @onComplete="endImageResize" 
                    ></image-uploader> -->


                    <!-- <handy-uploader
                        :documentAttachment.sync="handyAttachments" 
                        :fileUploaderType= "'simple'" 
                        :maxFileSize= "10240" 
                        :imageCompressor= "true" 
                        :imageCompressLevel= "0.8" 
                        :maxFileCount= "10" 
                        :badgeCounter= "true" 
                        :thumb= "false" 
                        :changeFileName="true" 
                        :addFileDescription="true" 
                        :addFileTag="true" 
                        :cardType= "'shaped'" 
                        :tags= "['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4']" 
                    >
                    </handy-uploader>
 -->

                  </div>
                  <!-- {{imageCount}}<hr>{{uploadList}} -->

                  <div v-if="imageCount > dropzoneOptions.maxFiles - 1" class="limit-warning">
                    Picture limit reached.
                  </div>
                  <div class="spacer s20"></div>
                  


                 <!--  <div v-if="uploadList.length > 0" class="uploaded-images">
                    <div class="image-wrap" v-for="image in uploadList" :key="image.imageID">
                      <div class="thumb"><img :src="image.dataURL" /></div>
                      <span class="delete is-large" @click="deleteImage(image)"></span>
                    </div>
                  </div> -->


                  <!-- <div v-if="uploadList.length > 0" class="uploaded-images">
                    <draggable v-model="uploadList" group="people" @start="drag=true" @end="drag=false">
                       <div class="image-wrap" v-for="image in uploadList" :key="image.imageID">
                         <div class="thumb"><img :src="image.dataURL" /></div>
                          <span class="delete is-large" @click="deleteImage(image)"></span>
                       </div>
                       <div class="clear"></div>
                    </draggable>
                    <div class="clear"></div>
                    <div class="spacer s10"></div>
                    <p v-if="uploadList.length > 1" class="italic">(Drag pictures to re-order)</p>
                  </div> -->


                  <div v-if="listOfDataUrls.length > 0" class="uploaded-images">
                    <draggable v-model="listOfDataUrls" group="people" @start="drag=true" @end="drag=false">
                       <div class="image-wrap" v-for="image in listOfDataUrls" :key="image.key">
                         <div class="thumb"><img :src="image.dataUrl" /></div>
                          <span class="delete is-large" @click="deleteUrl(image)"></span>
                          <div class="progress">
                            <span class="counter" v-if="image.progress < 100">{{image.progress}}%</span>
                            <span class="check" v-else><font-awesome-icon icon="check" /></span>
                          </div>
                       </div>
                       <div class="clear"></div>
                    </draggable>
                    <div class="clear"></div>
                    <div class="spacer s10"></div>
                    <p v-if="listOfDataUrls.length > 1" class="italic">(Drag pictures to re-order)</p>
                  </div>

                  <div class="clear"></div>

                  <div class="spacer s80"></div>

                  <!-- <div class="buttons">
                    <span class="button is-primary" v-show="tabThreeComplete" @click="listTab = 4">Next&nbsp;<font-awesome-icon icon="chevron-right" /></span>
                  </div> -->
                  

                </div>






                <!--  TAB 4 ========================================================================================================  -->
                <div class="tab-content-inner" v-if="listTab == 4">
                   <div class="spacer s30"></div>
                  <h3 class="tab-content-heading"><font-awesome-icon icon="info-circle" /> Let's tag this {{listingType}} to make it easier to find</h3>
                  <!-- <div class="tab-content-sub-heading">Everything in this section needs to be filled in.</div> -->
                  <div class="spacer s20"></div>

                  <div v-if="listingType == 'item'">
                    <div>
                      <label :class="{'empty': selectedIsNewOptions == ''}">Is this item new or used?</label>
                      <div class="choices-wrapper">
                        <ul class="choice-cloud tags">
                          <li v-for="(item, index) in isnewOptions" :key="item.slug"><span :class="{selected: item.selected}" @click="updatedSingleSelection(isnewOptions, index)">{{item.title}}</span></li>
                        </ul>
                      </div>
                    </div>
                    <div class="spacer s10"></div>
                    <hr>
                    <div class="spacer s20"></div>
                  </div>

                  <div v-if="listingType == 'item'">
                    <div>
                      <label :class="{'empty': selectedDealerOptions == ''}">Are you a private seller, shop or dealer?</label>
                      <div class="choices-wrapper">
                        <ul class="choice-cloud tags">
                          <li v-for="(item, index) in dealerOptions" :key="item.slug"><span :class="{selected: item.selected}" @click="updatedSingleSelection(dealerOptions, index)">{{item.title}}</span></li>
                        </ul>
                      </div>
                    </div>
                    <div class="spacer s10"></div>
                    <hr>
                    <div class="spacer s20"></div>
                  </div>


                  <div><!-- {{selectedCategoryOptions}} -->
                    <label :class="{'empty': selectedCategoryOptions == ''}">Which categories fit this {{listingType}} best?</label>
                    <div class="choices-wrapper">
                      <ul class="choice-cloud tags">
                        <li v-for="(item, index) in catCloud" :key="item.slug"><span :class="{selected: item.selected}" @click="updatedSelection(catCloud, index)">{{item.title}}</span></li>
                      </ul>
                    </div>
                  </div>
                  <div class="spacer s10"></div>
                  <hr>
                  <div class="spacer s20"></div>


                  <div><!-- {{selectedTagOptions}} -->
                    <label>Are there any other tags that describe this {{listingType}}?</label>
                    <div class="choices-wrapper">
                      <ul class="choice-cloud tags">
                        <li v-for="(item, index) in tagCloud" :key="item.slug"><span :class="{selected: item.selected}" @click="updatedSelection(tagCloud, index)">{{item.title}}</span></li>
                      </ul>
                    </div>
                  </div>
                  <div class="spacer s10"></div>
                  <hr>
                  <div class="spacer s30"></div>


                  <div class="buttons">
                    <span @click="showSuggestCats = true" class="button is-info"><font-awesome-icon icon="lightbulb" /> &nbsp; Suggest more categories</span>
                  </div>
                  <!-- <div class="buttons">
                    <span class="button is-primary" v-show="tabFourComplete" @click="listTab = 4">Next&nbsp;<font-awesome-icon icon="chevron-right" /></span>
                  </div> -->
                  

                </div>





                <!--  TAB 5 ========================================================================================================  -->
                <div class="tab-content-inner" v-if="listTab == 5">
                  <div class="spacer s30"></div>
                  <h3 class="tab-content-heading"><font-awesome-icon icon="check" /> Review your listing and Post it!</h3>

                  <div class="notification finish">
                    <ul>
                      <li @click="changeTabs(1)" v-if="info.title == ''" class="missing"><font-awesome-icon icon="times" />&nbsp; Give your {{listingType}} a title</li>
                      <li @click="changeTabs(1)" v-else><font-awesome-icon icon="check" />&nbsp; <span class="titled">Title:</span> {{info.title}}</li>
                      <li @click="changeTabs(1)" v-if="info.price != null && info.price != '' && listingType == 'item'"><font-awesome-icon icon="check" />&nbsp; <span class="titled">Price:</span> ${{Math.round(info.price)}} {{selectedPriceOptions.join(', ')}}</li>
                      <li @click="changeTabs(1)" v-if="(info.price == null || info.price == '') && listingType == 'item'"><font-awesome-icon icon="check" />&nbsp; <span class="titled">Price:</span> Open to any offer</li>
                      <li @click="changeTabs(1)" v-if="selectedInvoiceOptions.length > 0 && listingType == 'item'"><font-awesome-icon icon="check" />&nbsp; Supply invoice: {{selectedInvoiceOptions[0]}}</li>
                      <li @click="changeTabs(1)" v-if="selectedGstOptions.length > 0 && listingType == 'item'"><font-awesome-icon icon="check" />&nbsp; Price includes GST: {{selectedGstOptions[0]}}</li>
                      <li @click="changeTabs(1)" v-if="info.description == ''" class="missing"><font-awesome-icon icon="times" />&nbsp; Write a description about what your {{listingType}}</li>
                      <li @click="changeTabs(1)" v-else><font-awesome-icon icon="check" />&nbsp; <span class="titled">Description:</span> {{capitalise(info.description.substring(0,80))}}...</li>
                      <li @click="changeTabs(2)" v-if="address == '' && extras.suburbOverride == ''" class="missing"><font-awesome-icon icon="times" />&nbsp; Where is your {{listingType}} located?</li>
                      <!-- <li @click="changeTabs(2)" v-else><font-awesome-icon icon="check" />&nbsp; <span class="titled">Item Location:</span> {{address == '' ? extras.suburbOverride : (address.locality != undefined ? address.locality + ", " : "") + (address.administrative_area_level_2 != undefined ? address.administrative_area_level_2 + ", " : "") + (address.administrative_area_level_1 != undefined ? address.administrative_area_level_1 : "")}}</li> -->
                      <li @click="changeTabs(2)" v-else><font-awesome-icon icon="check" />&nbsp; <span class="titled">Location:</span> {{address == '' ? extras.suburbOverride : (address.locality != undefined ? address.locality + ", " : "") + (address.administrative_area_level_1 != undefined ? address.administrative_area_level_1 : "")}}</li>
                      <li @click="changeTabs(2)" v-if="extras.contactName == ''" class="missing"><font-awesome-icon icon="times" />&nbsp; Who is the best person to talk to?</li>
                      <li @click="changeTabs(2)" v-else><font-awesome-icon icon="check" />&nbsp; <span class="titled">Contact:</span> {{extras.contactName}}</li>
                      <li @click="changeTabs(2)" v-if="preferredContactMethods.length == 0" class="missing"><font-awesome-icon icon="times" />&nbsp; How would you like to be contacted?</li>
                      <li @click="changeTabs(2)" v-else><font-awesome-icon icon="check" />&nbsp; <span class="titled">Contact Methods:</span> {{preferredContactMethods.join(', ')}}</li>
                      <li @click="changeTabs(2)" v-if="listingType == 'item'"><font-awesome-icon icon="check" />&nbsp; Preferred Payment Methods: <span v-if="preferredPaymentMethods.length == 0">Accept any</span><span v-else>{{preferredPaymentMethods.join(', ')}}</span></li>
                      <li @click="changeTabs(4)" v-if="selectedIsNewOptions == '' && listingType == 'item'" class="missing"><font-awesome-icon icon="times" />&nbsp; Is this item new or used?</li>
                      <li @click="changeTabs(4)" v-if="selectedIsNewOptions != '' && listingType == 'item'"><font-awesome-icon icon="check" />&nbsp; <span class="titled">Item Condition:</span> {{selectedIsNewOptions.join(', ')}}</li>
                      <li @click="changeTabs(4)" v-if="selectedDealerOptions == '' && listingType == 'item'" class="missing"><font-awesome-icon icon="times" />&nbsp; Are you a private seller, shop or dealer?</li>
                      <li @click="changeTabs(4)" v-if="selectedDealerOptions != '' && listingType == 'item'"><font-awesome-icon icon="check" />&nbsp; <span class="titled">Seller Type:</span> {{selectedDealerOptions.join(', ')}}</li>
                      <li @click="changeTabs(4)" v-if="selectedCategoryOptions == '' && listingType == 'item'" class="missing"><font-awesome-icon icon="times" />&nbsp; Which categories fit this item best?</li>
                      <li @click="changeTabs(4)" v-if="selectedCategoryOptions != '' && listingType == 'item'"><font-awesome-icon icon="check" />&nbsp; <span class="titled">Selected Categories:</span> {{selectedCategoryOptions.join(', ')}}</li>
                      <li @click="changeTabs(4)" ><font-awesome-icon icon="check" />&nbsp; <span class="titled">Selected Tags:</span> {{selectedTagOptions.length > 0 ? selectedTagOptions.join(', ') : 'No tags saved'}}</li>
                      <li v-if="listOfDataUrls.length == 0 && listingType == 'item'" @click="changeTabs(3)" class="missing"><font-awesome-icon icon="times" />&nbsp; <span class="titled">No Pictures:</span> Add pictures to improve the chance of selling your item.</li>
                    </ul>

                    <div v-if="listOfDataUrls.length > 0" class="uploaded-images finish">
                       <div class="image-wrap" v-for="image in listOfDataUrls" :key="image.imageID">
                         <div class="thumb"><img :src="image.dataUrl" /></div>
                       </div>
                       <div class="clear"></div>
                    </div>

                    <div class="clear"></div>
                  </div>

                  <!-- <div v-if="bitsMissing">
                    
                  </div> -->

                  <!-- <p class="missing" v-if="imageCount < 1" @click="listTab = 3"><font-awesome-icon icon="exclamation-triangle" />&nbsp;<font-awesome-icon icon="camera" />&nbsp; Are you happy to proceed with no photos?<br>Add photos to improve the chance of selling your item.</p> -->


                  <div class="spacer s20"></div>
                  <div class="buttons centered">
                    <button :disabled="bitsMissing" class="button is-success is-large" v-show="tabFiveComplete" @click="saveListing(false)"><font-awesome-icon icon="thumbs-up" />&nbsp;Post {{listingType}}</button>
                    <button :disabled="info.title == ''" class="button is-light is-large" v-show="tabFiveComplete" @click="saveListing(true)"><font-awesome-icon icon="briefcase" />&nbsp;Save for Later</button>
                  </div>

                  <div class="spacer s70"></div>
                  

                </div>





                <div class="clear"></div>


            </div>

            <div v-show="listTab < 5" class="next-button" @click="nextTab()">
              <div class="npb-content">
                <span class="npb-icon"><font-awesome-icon icon="chevron-right" /></span>
                <span class="npb-text">Next</span>
              </div>
            </div>

        
      </div>


      <div v-else class="container">
        
        <div class="notification complete">
          <h3 class="centered">{{draftSaved ? 'Draft Saved' : 'Well done!'}}</h3>

          <h4>{{info.title}} has been {{draftSaved ? 'saved in your Dashboard' : 'listed on Xmarine'}}.</h4>
          <div class="spacer s20"></div>
          <div class="buttons centered">
            <router-link class="button is-warning" to="listings-home">Browse All Listings</router-link>
            <router-link class="button is-info" to="my-dashboard">View My Dashboard</router-link>
          </div>
          <div @click="showFeedback = true" class="feedback-prompt"><font-awesome-icon icon="comments" /></div>

          <div class="clear"></div>

        </div>

      </div>



      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showSuggestCats}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card is-primary">
          
            <header class="modal-card-head is-primary">
              <p class="modal-card-title">Your ideas are valuable</p>
            </header>
            <section class="modal-card-body">  

              <div v-if="!showThankYou">
                <h3>Suggest new categories or item tags below</h3>
                <p>(separate multiple items with a comma)</p>
                <div class="spacer s10"></div>
                <label>New Categories</label>
                <input type="text" class="input" placeholder="New category ideas..." v-model="suggestedCats" />
                <label>New Item Tags</label>
                <input type="text" class="input" placeholder="New tag ideas..." v-model="suggestedTags" />
              </div>
              <div v-else>
                <h3>Thanks for your suggestions!</h3>
                <p><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp;</p>
              </div>

            </section>
            <footer class="modal-card-foot">
              <button :disabled="suggestedCats == '' && suggestedTags == ''" class="button is-primary" @click="saveSuggestions()">Save Suggestions</button>
              <button class="button is-cancel" @click="close">Cancel</button>
              <div class="clear">
                
              </div>
            </footer>        

            <div class="clear">
              
            </div>

        </div>
      </div>


      <!-- <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showThankYou}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card is-primary">
          
            <header class="modal-card-head is-primary">
              <p class="modal-card-title">Thanks for your ideas!</p>
            </header>
            <section class="modal-card-body">       

              <h2>Your input is much appreciated!</h2>


            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="close">Done</button>
              <div class="clear">
                
              </div>
            </footer>        

            <div class="clear">
              
            </div>

        </div>
      </div> -->

      <customer-feedback v-if="showFeedback" :question="feedbackQuestion" :choices="feedbackChoices" :responseType="feedbackSingleResponse" v-on:feedbackCancelled="feedbackCancelled()"></customer-feedback>



    </div>

    <div v-else class="main">
      Something went wrong...
    </div>

  </div>


</template>



<script>

  import { slider, slideritem } from 'vue-concise-slider';
  import { db } from '../../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import 'firebase/functions';
  import NProgress from 'nprogress';
  const ListingProgress = () => import('../reusables/ListingProgress.vue');
  const CustomerFeedback = () => import('../reusables/CustomerFeedback.vue');
  // const PlacesSearch = () => import('../reusables/PlacesSearch.vue');
  // import GoogleAutocomplete from 'google-autocomplete-vue';
  import VueGoogleAutocomplete from 'vue-google-autocomplete';
  // import { BulmaDropdown } from 'vue-custom-google-autocomplete';

  import vue2Dropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import draggable from 'vuedraggable';
  // import ImageUploader from 'vue-image-upload-resize'
  // import handyUploader from 'handy-uploader/src/components/handyUploader';

  // firebase functions:config:set googleplaces.apikey="AIzaSyDL_bQSHiqBeczDCRR3_HcQjXfbJDHt3RA"
  
  export default {

    mounted() {
      firebase.auth().onAuthStateChanged(this.onUserLogin);
    },
    components: {
      ListingProgress,
      // BulmaDropdown,
      VueGoogleAutocomplete,
      vueDropzone: vue2Dropzone,
      draggable,
      CustomerFeedback,
      // PlacesSearch,
      // ImageUploader,
      // handyUploader,
    },
    props: {
      listingType: String,
    },
    computed: {
      userData() {
        return this.$store.getters.getUserData;
      },
      tabOneComplete() {
        // return this.info.title !== '' && this.info.description != '' && this.info.price != '' && this.info.price != null;
        return this.info.title !== '' && this.info.description != '';
      },
      tabOneProgress() {
        return (this.info.title !== '' ? 10 : 0) + (this.info.description !== '' ? 10 : 0);
      },
      tabOneClassObject() {
        return {
          'is-active': this.listTab == 1,
          'incomplete': !this.tabOneComplete && this.listTab > 1
        }
      },

      tabTwoComplete() {
        return (this.address != '' || this.extras.suburbOverride != '') && this.extras.contactName != '' && this.preferredContactMethods.length != 0;
      },
      tabTwoProgress() {
        return (this.address !== '' || this.extras.suburbOverride != '' ? 10 : 0) + (this.extras.contactName !== '' ? 10 : 0) + (this.preferredContactMethods.length > 0 ? 10 : 0);
      },
      tabTwoClassObject() {
        return {
          'is-active': this.listTab == 2,
          'incomplete': !this.tabTwoComplete && this.listTab > 2
        }
      },

      tabThreeComplete() {
        return this.imageCount > 0;
      },
      tabThreeProgress() {
        return this.imageCount > 0 ? 10 : 0;
      },
      tabThreeClassObject() {
        return {
          'is-active': this.listTab == 3,
          'incomplete': !this.tabThreeComplete && this.listTab > 3 && this.listingType == 'item'
        }
      },

      tabFourComplete() {
        return this.selectedIsNewOptions != '' && this.selectedDealerOptions != '' && this.selectedCategoryOptions != '';
      },
      tabFourProgress() {
        return (this.selectedIsNewOptions != '' ? 10 : 0) + (this.selectedDealerOptions != '' ? 10 : 0) + (this.selectedCategoryOptions != '' ? 10 : 0);
      },
      tabFourClassObject() {
        return {
          'is-active': this.listTab == 4,
          'incomplete': !this.tabFourComplete && this.listTab > 4
        }
      },
      
      tabFiveComplete() {
        return true;
      },
      tabFiveProgress() {
        return 0;
      },
      tabFiveClassObject() {
        return {
          'is-active': this.listTab == 5,
          'incomplete': !this.tabFiveComplete
        }
      },
      locationPlaceholder() {
        // return this.address == '' ? 'Start typing suburb...' : (this.address.locality != undefined ? this.address.locality + ", " : "") + (this.address.administrative_area_level_2 != undefined ? this.address.administrative_area_level_2 + ", " : "") + (this.address.administrative_area_level_1 != undefined ? this.address.administrative_area_level_1 : "");
        return this.address == '' ? 'Start typing suburb...' : (this.address.locality != undefined ? this.address.locality + ", " : "") + (this.address.administrative_area_level_1 != undefined ? this.address.administrative_area_level_1 : "");
      },

      totalProgress() {
        return (10 + this.tabOneProgress + this.tabTwoProgress + this.tabThreeProgress + this.tabFourProgress) + '%';
      },
      totalPercentage() {
        return (10 + this.tabOneProgress + this.tabTwoProgress + this.tabThreeProgress + this.tabFourProgress);
      },

      preferredContactMethods() {
        return this.contactMethods.filter((method) => method.selected).map((item) => item.title);
      },
      preferredPaymentMethods() {
        return this.paymentMethods.filter((method) => method.selected).map((item) => item.title);
      },
      selectedPriceOptions() {
        return this.priceOptions.filter((option) => option.selected).map((item) => item.title);
      },
      selectedOfferOptions() {
        return this.offerOptions.filter((option) => option.selected).map((item) => item.title);
      },
      selectedIsNewOptions() {
        return this.isnewOptions.filter((option) => option.selected).map((item) => item.title);
      },
      selectedDealerOptions() {
        return this.dealerOptions.filter((option) => option.selected).map((item) => item.title);
      },
      selectedCategoryOptions() {
        return this.catCloud.filter((option) => option.selected).map((item) => item.title);
      },
      selectedTagOptions() {
        return this.tagCloud.filter((option) => option.selected).map((item) => item.title);
      },
      selectedInvoiceOptions() {
        return this.invoiceOptions.filter((option) => option.selected).map((item) => item.title);
      },
      selectedGstOptions() {
        return this.gstOptions.filter((option) => option.selected).map((item) => item.title);
      },

      bitsMissing() {
        return this.info.title == '' || this.info.description == '' || (this.address == '' && this.extras.suburbOverride == '') || this.extras.contactName == '' || this.preferredContactMethods.length == 0;
      },

    },
    data() {
      return {
        // progress: '20%',
        firebaseid: '',
        // percentage: 20,
        listTab: 1,

        info: {
          title: '',
          description: '',
          price: null,
        },
        catCloud: [],
        tagCloud: [],
        extras: {
          suburbOverride: '',
          contactName: '',
          contactMethod: [],
          contactPhone: '',
          contactEmail: '',
        },
        priceOptions: [
          {selected: false, title: 'I\'m open to reasonable offers', slug: 'openToOffers'},
        ],
        offerOptions: [
          {selected: false, title: 'Make me an offer', slug: 'makeAnOffer'},
        ],
        invoiceOptions: [
          {selected: false, title: 'Yes', slug: 'yes'},
          {selected: false, title: 'No', slug: 'no'},
          {selected: false, title: 'Not applicable', slug: 'na'},
        ],
        gstOptions: [
          {selected: false, title: 'Yes', slug: 'yes'},
          {selected: false, title: 'No', slug: 'no'},
          {selected: false, title: 'Not applicable', slug: 'na'},
        ],
        contactMethods: [
          {selected: false, title: 'Phone call', slug: 'phone'},
          {selected: false, title: 'SMS', slug: 'sms'},
          {selected: false, title: 'Email', slug: 'email'},
        ],
        paymentMethods: [
          {selected: false, title: 'Cash', slug: 'cash'},
          {selected: false, title: 'Bank Transfer', slug: 'eft'},
          {selected: false, title: 'PayPal', slug: 'paypal'},
          {selected: false, title: 'Crypto Currency', slug: 'crypto'},
          {selected: false, title: 'Other', slug: 'other'},
        ],
        isnewOptions: [
          {selected: false, title: 'Brand New', slug: 'new'},
          {selected: false, title: 'Used', slug: 'used'},
        ],
        dealerOptions: [
          {selected: false, title: 'Private listing', slug: 'private'},
          {selected: false, title: 'Shop or Business', slug: 'shop'},
          {selected: false, title: 'Dealer', slug: 'dealer'},
        ],

        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

        tempAttachments: [],
        attachments: [],
        dropzoneOptions: {
          // url: "https://httpbin.org/post",
          // thumbnailWidth: 600,
          // thumbnailHeight: 600,
          // addRemoveLinks: true,
          acceptedFiles: 'image/*',
          // dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Drag Images or Click Here</p>
          //     <p class="form-text">Allowed Files: .jpg, .jpeg, .png</p>`,
          // maxFilesize: 4,
          // maxFiles: 8,
          // dictRemoveFile: 'Remove',
          // resizeWidth: 500, 
          // resizeHeight: 500,
          // resizeQuality: 0.1,
          //
          url: `https://httpbin.org/post`,
          // File Size allowed in MB
          // maxFilesize: 102400000,
          maxFilesize: 20,
          maxThumbnailFilesize: 20,
          // thumbnailMethod: 'contain',
          // Authentication Headers like Access_Token of your application
          // headers: {
          //   Authorization: `Access Token`
          // },
          // The way you want to receive the files in the server
          paramName: function(n) {
            return "file[]";
          },
          // dictDefaultMessage: "Upload Files Here xD",
          includeStyling: false,
          previewsContainer: false,
          thumbnailWidth: 500,
          thumbnailHeight: 500,
          uploadMultiple: true,
          parallelUploads: 20, 
          maxFiles: 8,
          resizeWidth: 600, 
          resizeHeight: 600,
          resizeQuality: 0.6,
        },
        myUploads: [],
        uploadList: [],
        listOfDataUrls: [],
        imageCount: 0,
        currentUpload: '',
        maxPicsReached: false,

        showSuggestCats: false,
        suggestedCats: '',
        suggestedTags: '',
        showThankYou: false,
        showFeedback: false,
        feedbackQuestion: 'How would you rate your experience listing your item?',
        feedbackChoices: [
          {selected: false, title: 'I struggled a bit'},
          {selected: false, title: 'It wasn\'t too bad'},
          {selected: false, title: 'It was easy!'},
        ],
        feedbackSingleResponse: true,

        // townList: {},

        // selected: null,
        // options: {
        //   apiKey: process.env.VUE_APP_PLACE_API_KEY,
        //   deepSearch: false,
        //   cors: true,
        //   // cors: false,
        //   focus: false,
        //   params: {
        //     // location: '43.3,5.4',
        //     // radius: 1000,
        //     // language: 'fr'.
        //     components: 'country:au'
        //   }
        // },

        address: '',
        showManualSuburbEntry: false,

        saveType: '',
        listingFinished: false,
        draftSaved: false,

        hasImage: false,

        handyAttachments: [],



      }
    },

    methods: {

      // acceptFile(file, done) {
      //   let self = this;
      //   var mime_type = file.type;
      //   if ( mime_type != 'image/gif'){
      //       self.dropzoneOptions.resizeWidth = 450;
      //       self.dropzoneOptions.resizeMimeType = 'image/jpeg';
      //       self.dropzoneOptions.resizeQuality = 0.55;
      //       self.dropzoneOptions.width = 500;
      //       console.log(self.dropzoneOptions);
      //       done();
      //       return;
      //   }
      //   done();
      // },

      onUserLogin: function(user) {
        if (user) {
          this.firebaseid = user.uid;
          this.extras.contactName = this.userData.username;
          this.extras.contactPhone = this.userData.userphone;
          this.extras.contactEmail = this.userData.useremail;
        }
        this.loadCatCloud();
        this.loadTagCloud();
      },

      loadCatCloud() {
        let self = this;
        let collection = this.listingType == 'job' ? 'jobCats' : this.listingType == 'service' ? 'serviceCats' : 'itemCats';
        db.collection('helpers').doc('helperUserCats').collection(collection).orderBy('index','asc').onSnapshot((querySnapshot) => {
          self.catCloud = [];
          querySnapshot.forEach((doc) => {
            self.catCloud.push({
              selected: false,
              title: doc.data().title,
              slug: doc.data().slug ? doc.data().slug : '',
            });
          });
        });
      },

      loadTagCloud() {
        let self = this;
        let collection = this.listingType == 'job' ? 'jobTags' : this.listingType == 'service' ? 'serviceTags' : 'itemTags';
        db.collection('helpers').doc('helperTags').collection(collection).orderBy('index','asc').onSnapshot((querySnapshot) => {
          self.tagCloud = [];
          querySnapshot.forEach((doc) => {
            self.tagCloud.push({
              selected: false,
              title: doc.data().title,
              slug: doc.data().slug ? doc.data().slug : '',
            });
          });
        });
      },

      nextTab() {
        window.scrollTo(0,0);
        this.listTab++;
      },

      close() {
        this.showSuggestCats = false;
        this.showThankYou = false;
        this.suggestedCats = '';
        this.suggestedTags = '';
        this.showFeedback = false;
      },

      feedbackCancelled() {
        this.showFeedback = false;
      },

      changeTabs(tab) {
        window.scrollTo(0,0);
        this.listTab = tab;
      },

      capitalise(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      },

      /**
      * When the location found
      * @param {Object} addressData Data of the found location
      * @param {Object} placeResultData PlaceResult object
      * @param {String} id Input container ID
      */
      getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData;
      },

      clearLocation() {
        this.$refs.address.clear();
        this.address = '';
        this.showManualSuburbEntry = false;
        this.extras.suburbOverride = '';
      },

      clearContactName() {
        this.extras.contactName = '';
      },

      updatedMethod(cat, index) {
        this.contactMethods[index].selected = !this.contactMethods[index].selected;
      },

      updatedSelection(list, index) {
        list[index].selected = !list[index].selected;
      },

      updatedSingleSelection(list, index) {
        list.forEach((item) => {
          item.selected = false;
        });
        list[index].selected = !list[index].selected;
      },

      isNumber: function($evt) {
        $evt = ($evt) ? $evt : window.event;
        var charCode = ($evt.which) ? $evt.which : $evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          $evt.preventDefault();
        } else {
          return true;
        }
      },

      isEmailValid: function() {
        return (this.extras.contactEmail == "") ? false : (this.reg.test(this.extras.contactEmail)) ? true : false;
      },

      // thousandSeparator(amount) {
      //   if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
      //     return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      //   } else {
      //     return amount;
      //   }
      // },

      async saveImage() {

        NProgress.start();
        let self = this;
        // this.saveImageModal = false;
        // console.log('Upload:',this.currentUpload);
        // console.log('Filetype',this.fileType);
        var imageName = uuid.v1();
        var imageLabel = this.tempLabel == '' ? 'No name' : this.tempLabel;
        this.isLoading = true;

        try {
          let file = self.currentUpload;
          var metadata = {
            // contentType: "image/png"
          };
          var storageRef = firebase.storage().ref();
          var imageRef = storageRef.child(`vessel-uploads/${self.currentVesselID}/${imageName}.png`);
          await imageRef.put(file, metadata);
          var downloadURL = await imageRef.getDownloadURL();

          // db.collection('vessels').doc(self.currentVesselID).collection('uploads').doc(imageName).set({
          //   imageUrl: downloadURL,
          //   imageLabel: imageLabel,
          //   cat: self.fileType,
          //   type: self.currentUpload.type
          // })
          // .then(function() {
          //   NProgress.done();
          //   var addVesselRevision = firebase.functions().httpsCallable('addVesselRevision');
          //   addVesselRevision({
          //     userName: self.firebasedisplayname,
          //     userID: self.firebaseid,
          //     vesselName: self.currentVesselName,
          //     vesselID: self.currentVesselID,
          //     revisionDetails: 'New document uploaded',
          //   });   
          //   self.fileType = '';
          //   self.tempLabel = '';
          // });

        } catch (error) {
          console.log(error);
          NProgress.done();
          self.fileType = '';
          self.tempLabel = '';
        }
        this.$refs.imgDropZone.removeFile(this.currentUpload);

      },

      // cancelUpload() {
      //   this.saveImageModal = false;
      //   this.$refs.imgDropZone.removeFile(this.currentUpload);
      //   this.currentUpload = '';
      //   this.fileType = '';
      //   this.tempLabel = '';
      // },


// @vdropzone-thumbnail="thumbnailAdded" 
// @vdropzone-file-added ="afterFiledAdded" 
// @vdropzone-sending="beforeSending" 
// @vdropzone-success="uploadedSuccessfully" 
// @vdropzone-complete="afterComplete" 
// @vdropzone-max-files-reached="maxReached" 
// @vdropzone-removed-file="fileRemoved"> 



      uploadProgress(file, progress, bytesSent) {
        // console.log("File Upload Progress", progress);
        // this.tempAttachments.map(attachment => {
        //   if (attachment.title === file.name) {
        //     attachment.progress = `${Math.floor(progress)}`;
        //   }
        // });
        this.listOfDataUrls.map(image => {
          if (image.title === file.name) {
            image.progress = `${Math.floor(progress)}`;
          }
        });
      },

      // checkProgress(image) {
      //   return image.progress === null ? false : true;
      // },

      uploadError(file, message, xhr) {
        console.log('Error:', message);
      },

      async afterFiledAdded(file) {
        // console.log('afterFiledAdded');
        // console.log(file.width);
        // console.log(file.dataUrl);
        // let ratio = 600 / parseInt(file.width);
        // console.log(ratio);
        // file.width = file.width * ratio;
        // file.height = file.height * ratio;
        // console.log(file);

        // console.log(file);

        // let attachment = {};
        // attachment._id = file.upload.uuid;
        // attachment.title = file.name;
        // attachment.type = "file";
        // attachment.extension = "." + file.type.split("/")[1];
        // attachment.user = JSON.parse(localStorage.getItem("user"));
        // attachment.content = "File Upload by Select or Drop";
        // attachment.thumb = file.dataURL;
        // attachment.thumb_list = file.dataURL;
        // attachment.isLoading = true;
        // attachment.progress = null;
        // attachment.size = file.size;
        // this.tempAttachments = [...this.tempAttachments, attachment];

        // console.log(file.dataUrl);
        // file.upload.dataUrl = file.dataUrl;
        // this.myUploads.push(file.upload);
        // this.imageCount++;
      },

      async thumbnailAdded(file, dataUrl) {
        // console.log(dataUrl);
        // console.log(file.width);
        // this.listOfDataUrls.push(dataUrl);

        if (this.dropzoneOptions.maxFiles > this.imageCount) {
          this.listOfDataUrls.push({
            key: file.upload.uuid,
            dataUrl: dataUrl,
            progress: 0,
            title: file.name
          });
          this.imageCount = this.listOfDataUrls.length;
        }
          
        // this.listOfDataUrls[file.upload.uuid] = dataUrl;
      },  

      async beforeSending(file) {
        // console.log('beforeSending');
        // console.log(file);
      },

      async uploadedSuccessfully(file, response) {
        // console.log('uploadedSuccessfully');
        // console.log(response);
      },

      async maxReached() {
        // console.log('maxReached');
        this.maxPicsReached = true;
      },

      async fileRemoved(file) {
        console.log('fileRemoved here');
        // console.log(file.upload);
        this.myUploads = this.myUploads.filter(item => item.uuid !== file.upload.uuid);
        this.uploadList = this.uploadList.filter(item => item.imageID !== file.upload.uuid);
        // this.imageCount--;
        this.imageCount = this.myUploads.length;

      },

      async afterComplete(file) {
        // console.log('afterComplete');
        // console.log(file.upload);
        // let self = this;
        // let tempFile = file;

        // tempFile.imageID = file.upload.uuid;
        // if (this.dropzoneOptions.maxFiles > this.imageCount) {
        //   this.myUploads.push(file.upload);
        //   this.uploadList.push(tempFile);
        //   this.imageCount = this.myUploads.length;
        // }


        // this.$refs.imgDropZone.processQueue();

        // this.$refs.imgDropZone.removeFile(file);


        // this.currentUpload = file;
        

        // var imageName = uuid.v1();
        // this.isLoading = true;
        // try {
        //     //save image
        //     let file = upload;
        //     var metadata = {
        //       contentType: "image/png"
        //     };
        //     var storageRef = firebase.storage().ref();
        //     var imageRef = storageRef.child(`${fileType}/${imageName}.png`);
        //     await imageRef.put(file, metadata);
        //     var downloadURL = await imageRef.getDownloadURL();
        //     this[fileType].push({ src: downloadURL });

        // } catch (error) {
        //   console.log(error);
        // }
        // this.$refs.imgDropZone.removeFile(upload);

      },

      // template: function () {
      //   return `<div class="dz-preview dz-file-preview">
      //           <div class="dz-image test">
      //               <div data-dz-thumbnail-bg></div>
      //           </div>
      //           <div class="dz-details">
      //               <div class="dz-size"><span data-dz-size></span></div>
      //               <div class="dz-filename"><span data-dz-name></span></div>
      //           </div>
      //           <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
      //           <div class="dz-error-message"><span data-dz-errormessage></span></div>
      //           <div class="dz-success-mark"><i class="fa fa-check"></i></div>
      //           <div class="dz-error-mark"><i class="fa fa-close"></i></div>
      //       </div>
      //   `;
      // },
      // thumbnail: function(file, dataUrl) {
      //   var j, len, ref, thumbnailElement;
      //   if (file.previewElement) {
      //       file.previewElement.classList.remove("dz-file-preview");
      //       ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
      //       for (j = 0, len = ref.length; j < len; j++) {
      //           thumbnailElement = ref[j];
      //           thumbnailElement.alt = file.name;
      //           thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
      //       }
      //       return setTimeout(((function(_this) {
      //           return function() {
      //               return file.previewElement.classList.add("dz-image-preview");
      //           };
      //       })(this)), 1);
      //   }
      // },

      preview(url) {
        console.log('URL', url);
        this.selectedUrl = url;
        this.previewModal = true;
      },

      deleteUrl(image) {
        this.listOfDataUrls = this.listOfDataUrls.filter(item => item.key !== image.key);
        this.imageCount = this.listOfDataUrls.length;
      },

      deleteImage(sentFile) {

        // console.log(deleteID);
        // let self = this;

        this.myUploads = this.myUploads.filter(item => item.uuid !== sentFile.imageID);
        this.uploadList = this.uploadList.filter(item => item.imageID !== sentFile.imageID);
        // this.imageCount--;
        this.imageCount = this.myUploads.length;

        this.$refs.imgDropZone.removeFile(sentFile)
        
        // let message;
        // message = {
        //   title: 'Confirm Delete?',
        //   body: 'This cannot be undone.'
        // };
        // let options = {
        //   okText: 'DELETE',
        //   backdropClose: true,
        //   cancelText: 'Cancel',
        // };
        // this.$dialog
        //   .confirm(message, options)
        //   .then(function() {

        //     NProgress.start();

        //     db.collection('vessels').doc(self.currentVesselID).collection('uploads').doc(id).delete()
        //     .then(function() {
        //       NProgress.done();
        //     });          


        //   })
        //   .catch(function() {
        //     NProgress.done();
        //   });





      },

      saveSuggestions: function() {
        let self = this;
        this.showThankYou = true;
        var saveCatSuggestions = firebase.functions().httpsCallable('saveCatSuggestions');
        saveCatSuggestions({
          userName: self.userData.username,
          userID: self.userData.userid,
          suggestedCats: self.suggestedCats,
          suggestedTags: self.suggestedTags,
        }); 

        setTimeout(() => {
          self.close();
        }, 1500);


      }, 

      async getImageUrls() {
        let self = this;
        let imageUrls = [];
        let storageRef = firebase.storage().ref();
        //
        // await Promise.all(this.uploadList.map(async (file) => {
        //   console.log(file.upload);
        //   let imageRef = storageRef.child(`listing-images/${self.firebaseid}/${file.upload.uuid}`);
        //   await imageRef.put(file).then(async () => {
        //     let downloadURL = await imageRef.getDownloadURL();
        //     imageUrls.push(downloadURL);
        //   }).catch((err) => console.log('Error',err));
        // }));
        
        await Promise.all(this.listOfDataUrls.map(async (image) => {
          // console.log(file.upload);
          let imageRef = storageRef.child(`listing-images/${self.firebaseid}/${image.key}`);
          // var message = file;
          await imageRef.putString(image.dataUrl, 'data_url').then(async (snapshot) => {
            let downloadURL = await imageRef.getDownloadURL();
            imageUrls.push(downloadURL);
          });
        }));
        // console.log(imageUrls);
        return imageUrls;
      },

      async getImageThumb() {
        let self = this;
        let imageThumb = '';
        let storageRef = firebase.storage().ref();
        let file = this.uploadList[0];
        let imageRef = storageRef.child(`listing-images/${self.firebaseid}/${file.upload.uuid}`);
        await imageRef.put(file).then(async () => {
          let downloadURL = await imageRef.getDownloadURL();
          imageThumb = downloadURL;
        }).catch((err) => console.log('Error',err));
        return imageThumb;
      },


      saveListing(isDraft) {
        let self = this;
        let message;
        let imageUrls = [];
        let imageThumb = '';
        this.draftSaved = isDraft;
        message = {
          title: isDraft ? 'Save this ad for later?' : 'Post this new listing?',
          body: isDraft ? 'You can finish it at any time from your profile page' : 'It will be pending until approved',
        };
        let options = {
          okText: isDraft ? 'Yes, SAVE' : 'Yes, POST',
          backdropClose: true,
          cancelText: "Cancel",
        };
        this.$dialog
          .confirm(message, options)
          .then(async function() {
              NProgress.start();

              if (self.uploadList.length > 0) imageUrls = await self.getImageUrls();

              // if (self.uploadList.length > 0) imageThumb = await self.getImageThumb();

              let listingData = {
                // status: isDraft ? 'draft' : 'pending',
                status: isDraft ? 'draft' : 'live',
                listingType: self.listingType,
                title: self.info.title,
                description: self.info.description,
                price: self.info.price ? String(self.info.price) : '',
                selectedPriceOptions: self.selectedPriceOptions,
                address: self.address == '' ? '' : (self.address.locality != undefined ? self.address.locality + ", " : "") + (self.address.administrative_area_level_1 != undefined ? self.address.administrative_area_level_1 : ""),
                contactName: self.extras.contactName,
                preferredContactMethods: self.preferredContactMethods,
                preferredPaymentMethods: self.preferredPaymentMethods,
                selectedIsNewOptions: self.selectedIsNewOptions,
                selectedDealerOptions: self.selectedDealerOptions,
                selectedCategoryOptions: self.selectedCategoryOptions,
                selectedTagOptions: self.selectedTagOptions,
                selectedInvoiceOptions: self.selectedInvoiceOptions,
                selectedGstOptions: self.selectedGstOptions,
                listerUserName: self.userData.username ? self.userData.username : 'Unknown',
                listerUserID: self.userData.userid ? self.userData.userid : '',
                listerUserPhone: self.userData.userphone ? self.userData.userphone : 'Unknown',
                listerUserEmail: self.userData.useremail ? self.userData.useremail : 'Unknown',
                imageUrls: imageUrls,
                imageThumb: imageThumb,
              };

              console.log('LISTING DATA:',listingData);

              var saveNewListing = firebase.functions().httpsCallable('saveNewListing');
              saveNewListing({
                listingData: listingData,
              }); 

              self.saveType = isDraft ? 'draft' : 'complete';
              self.listingFinished = true;


              NProgress.done();

          })
          .catch(function() {
              NProgress.done();
          });

      },

      async transformFile(file, done) {
        // if ((this.dropzoneOptions.resizeWidth || this.dropzoneOptions.resizeHeight) && file.type.match(/image.*/)) {
        //   return this.resizeImage(file, this.dropzoneOptions.resizeWidth, this.dropzoneOptions.resizeHeight, this.dropzoneOptions.resizeMethod, async function (result) {
        //      file.md5 = await calculateMD5(result);
        //      file.presign = await initUpload(file.name, file.type, file.md5);
        //      done(result);
        //    });
        // } else {
        //   file.md5 = await calculateMD5(file);
        //   file.presign = await initUpload(file.name, file.type, file.md5);
        //   return done(file);
        // }
        let ratio = 600 / file.width;
        file.width = file.width * ratio;
        file.height = file.height * ratio;
        console.log(file);
        // if ((this.dropzoneOptions.resizeWidth || this.dropzoneOptions.resizeHeight) && file.type.match(/image.*/)) {
        //   return this.resizeImage(file, this.dropzoneOptions.resizeWidth, this.dropzoneOptions.resizeHeight, this.dropzoneOptions.resizeMethod, async function (result) {
        //      file.md5 = await calculateMD5(result);
        //      file.presign = await initUpload(file.name, file.type, file.md5);
        //      done(result);
        //    });
        // } else {
        //   file.md5 = await calculateMD5(file);
        //   file.presign = await initUpload(file.name, file.type, file.md5);
        //   return done(file);
        // }
        return done(file);
      },



      // setImage: function (file) {
      //   this.hasImage = true
      //   this.image = file
      // },

      // saveListing(isDraft) {
      //   let self = this;
      //   let message;
      //   let imageUrls = [];
      //   let imageThumb = '';
      //   this.draftSaved = isDraft;
      //   message = {
      //     title: isDraft ? 'Save this ad for later?' : 'Post this new listing?',
      //     body: isDraft ? 'You can finish it at any time from your profile page' : 'It will be pending until approved',
      //   };
      //   let options = {
      //     okText: isDraft ? 'Yes, SAVE' : 'Yes, POST',
      //     backdropClose: true,
      //     cancelText: "Cancel",
      //   };
      //   this.$dialog
      //     .confirm(message, options)
      //     .then(async function() {
      //         NProgress.start();

      //         if (self.uploadList.length > 0) imageUrls = await self.getImageUrls();

      //         if (self.uploadList.length > 0) imageThumb = await self.getImageThumb();

      //         let listingData = {
      //           // status: isDraft ? 'draft' : 'pending',
      //           status: isDraft ? 'draft' : 'live',
      //           listingType: self.listingType,
      //           title: self.info.title,
      //           description: self.info.description,
      //           price: self.info.price ? String(self.info.price) : '',
      //           selectedPriceOptions: self.selectedPriceOptions,
      //           address: self.address == '' ? '' : (self.address.locality != undefined ? self.address.locality + ", " : "") + (self.address.administrative_area_level_1 != undefined ? self.address.administrative_area_level_1 : ""),
      //           contactName: self.extras.contactName,
      //           preferredContactMethods: self.preferredContactMethods,
      //           preferredPaymentMethods: self.preferredPaymentMethods,
      //           selectedIsNewOptions: self.selectedIsNewOptions,
      //           selectedDealerOptions: self.selectedDealerOptions,
      //           selectedCategoryOptions: self.selectedCategoryOptions,
      //           selectedTagOptions: self.selectedTagOptions,
      //           selectedInvoiceOptions: self.selectedInvoiceOptions,
      //           selectedGstOptions: self.selectedGstOptions,
      //           listerUserName: self.userData.username ? self.userData.username : 'Unknown',
      //           listerUserID: self.userData.userid ? self.userData.userid : '',
      //           listerUserPhone: self.userData.userphone ? self.userData.userphone : 'Unknown',
      //           listerUserEmail: self.userData.useremail ? self.userData.useremail : 'Unknown',
      //           imageUrls: imageUrls,
      //           imageThumb: imageThumb,
      //         };

      //         console.log('LISTING DATA:',listingData);

      //         var saveNewListing = firebase.functions().httpsCallable('saveNewListing');
      //         saveNewListing({
      //           listingData: listingData,
      //         }); 

      //         self.saveType = isDraft ? 'draft' : 'complete';
      //         self.listingFinished = true;


      //         NProgress.done();

      //     })
      //     .catch(function() {
      //         NProgress.done();
      //     });



      // },



    }

  }


</script>



<style lang="scss" scoped>
  
  .next-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 90px;
    height: 90px;
    background: #0ae08e;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
    .npb-content {
      color: #fff;
      .npb-icon {
        display: block;
        margin: 17px auto 1px;
        display: block;
        font-size: 1.2rem;
      }
      .npb-text {
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.3rem;
        display: block;
      }
    }
      
    &:hover {
      // background: #40f178;
      transform: scale(1.05);
    }
  }
  .dropdown {
    width: 100% !important;
  }
  .tabs {
    margin-top: 3rem;
    ul {
      li {
        &.incomplete {
          a {
            // background: #f9a5a5;
            // border-color: #f9a5a5;
            color: red;
          }
          // &.is-active {
          //   a {
          //     background: #fb4848;
          //     border-color: #fb4848;
          //   }
          // }
        }
        &.is-active {
          a {
            background: #6cd3fb;
            border-color: #6cd3fb;
          }
        }
      }
    }
  }

  .modal-card-body {
    h3 {
      font-size: 1.3rem;
      font-weight: bold;
      color: #0a86e0;
    }
    svg {
      font-size: 2rem;
      font-weight: bold;
      color: #0a86e0;
      margin-top: 1rem;
    }
    label {
      text-align: left;
      display: block;
      margin-bottom: 8px;   
      font-weight: bold;
      color: #666; 
    }
    input[type="text"], input[type="tel"], input[type="email"], textarea {
      padding: 20px;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        border-color: skyblue;
      }
      &.compact {
        width: auto;
        min-width: 320px;
        display: block;
        padding: 11px;
      }
    }
  }

  .tab-content {
    .tab-content-inner {
      padding-bottom: 100px;
    }
    .tab-content-heading {
      font-size: 1.4rem;
      font-weight: bold;
      color: steelblue;
      margin: 15px auto 10px;
    }
    label {
      text-align: left;
      display: block;
      margin-bottom: 8px;   
      font-weight: bold;
      color: #666;   
      &.empty {
        color: #ec3037;
      }
    }
    .tab-content-sub-heading {
      font-style: italic;
      font-size: 1.1rem;
    }
    .sub-prompt {
      text-align: left;
      margin-top: 10px;
      display: block;
      font-size: 0.9rem;
    }
    .manual-suburb {
      margin: 10px 0 30px;
    }
    input[type="text"], input[type="tel"], input[type="email"], textarea {
      padding: 20px;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        border-color: skyblue;
      }
      &.compact {
        width: auto;
        min-width: 320px;
        display: block;
        padding: 11px;
      }
    }
    .dollar-wrapper {
      position: relative;
      &:before {
        // content: "&#36;";
        content: "$";
        position: absolute;
        left: 20px;
        z-index: 9;
        top: 19px;
        font-size: 1.3rem;
        color: mediumseagreen;
        font-weight: bold;
      }
      input[type="tel"] {
        padding: 20px 20px 20px 35px;
        color: mediumseagreen;
        font-weight: bold;
        font-size: 1.3rem;
        line-height: 1px;
        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          font-weight: normal;
          font-size: 1rem;
          color: #bbb;  
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          font-weight: normal;
          font-size: 1rem;
          color: #bbb;  
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          font-weight: normal;
          font-size: 1rem;
          color: #bbb;  
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-weight: normal;
          font-size: 1rem;
          color: #bbb;  
        }
      }
    }
    .short-description-draft {
      padding: 20px;
      background: #f9f9f9;
      color: dodgerblue;
      font-weight: bold;
      text-align: left;
      .sd-heading {
        color: #666;
      }
    }
    .choices-wrapper {
      .choice-cloud {
        margin-top: 1em;
        text-align: left;
        li {
          display: inline-block;
          cursor: pointer;
          margin: 0 12px 1.3rem 0;
          span {
            background: #e4e4e4;
            transition: 0.3s;
            display: block;
            padding: 10px 20px;
            font-size: 1rem;
            border-radius: 30px;
            // &:hover {
            //   color: #fff;
            //   background: #6cc8fb;
            // }
            &.selected {
              background: #8AD1F6;
              color: #fff;
            }
          }
        }
        &.tags {
          li {
            span {
              // &:hover {
              //   background: #69e8c1;
              // }
              &.selected {
                background: #2dc596;
              }
            }
          }
        }
      }
    }
    p {
      &.missing {
        color: red;
      }
    }
    .limit-warning {
      color: red;
      font-weight: bold;
      margin-top: 15px;
    }
    .uploaded-images {
      margin-bottom: 100px;
      margin-top: 3rem;
      text-align: left;
      .image-wrap {
        width: 150px;
        height: 150px;
        // float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        cursor: pointer;
        transition: 0.2s;
        opacity: 1;
        display: inline-block;
        position: relative;
        .thumb {
          text-align: center;
          height: 150px;
          overflow: hidden;
          img {
            display: block;
          }
        }
        span.delete {
          display: block;
          // margin: 12px auto;
          position: absolute;
          top: -10px;
          right: -10px;
          // background: #f76767;
          background: #bfbfbf;
          transition: 0.3s;
        }
        &:hover {
          // opacity: 0.8;
          border-color: #444;
          span.delete {
            background: #f76767;
          }
        }
        .progress {
          position: absolute;
          bottom: 1px;
          right: 1px;
          width: 36px;
          height: 36px;
          font-size: 1.5rem;
          color: #1aff00;
          span {
            &.counter {
              font-size: 14px;
              color: #fff;
            }
            &.check {

            }
          }
        }
      }
      &.finish {
        margin-bottom: 1rem;
        .image-wrap {
          width: 100px;
          height: 100px;
          cursor: auto;
          background: #fff;
          border: none;
          display: inline-block;
          float: none;
          &:hover {
            border: none;
          }
        }
      }
    }
  }

  #location-search {
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    font-size: 1rem;
    &:focus, &:active, &:focus-visible {
      box-shadow: none;
      border-color: skyblue;
      border: none !important;
    }
  }

  .notification {
    &.finish {
      text-align: left;
      padding: 2rem 3rem;
      margin-top: 2rem;
      a {
        text-decoration: none;
      }
      ul {
        li {
          text-indent: -38px;
          margin-left: 30px;
          margin-bottom: 11px;
          font-size: 1.1rem;
          color: steelblue;
          cursor: pointer;
          &.missing {
            color: red;
          }
        }
      }
    }
    &.complete {
      text-align: center;
      padding: 2rem 3rem;
      margin-top: 2rem;
      a {
        text-decoration: none;
      }
      h3 {
        margin-bottom: 0.6rem;
        color: deepskyblue;
        font-size: 1.3rem;
        font-weight: bold;
      }
      h4 {
        margin-bottom: 0.6rem;
        color: deepskyblue;
        font-size: 1.3rem;
        font-weight: bold;
      }
    }
  }

  ///////   


  #xcustomdropzone {
    background-color: #e4e4e4;
    font-family: 'Arial', sans-serif;
    letter-spacing: 0.2px;
    color: #777;
    transition: background-color .2s linear;
    // height: 200px;
    padding: 40px;
    .dz-preview {
      .dz-image.test {
        width: 150px;
        height: 150px;
      }
    }
  }

  #xcustomdropzone > .dz-preview {
    width: 150px;
    height: 150px;
  }

  #xcustomdropzone .dz-preview {
    width: 160px;
    display: inline-block
  }
 #xcustomdropzone .dz-preview .dz-image {
    width: 80px;
    height: 80px;
    margin-left: 40px;
    margin-bottom: 10px;
  }
  #xcustomdropzone.dropzone .dz-preview.dz-image-preview {
    width: 80px;
    height: 80px;
    margin-left: 40px;
    margin-bottom: 10px;
  }
  #xcustomdropzone .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 50%;
    background-size: contain;
  }
  #xcustomdropzone .dz-preview .dz-image > img {
    width: 100% !important;
  }

   #xcustomdropzone .dz-preview .dz-details {
    color: white;
    transition: opacity .2s linear;
    text-align: center;
  }
  #xcustomdropzone .dz-success-mark, .dz-error-mark, .dz-remove {
    display: none;
  }

  .vue-dropzone>.dz-preview .dz-image {
    width: 100px;
  }



</style>













